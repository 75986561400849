import { FC } from 'react';

import Crawler from 'components/Crawler';
import { Price, StyledOldPrice } from 'components/ProductPrice/index.styled';

import { CustomTypographyVariants } from 'assets/theme/theme-declarations';
import { Price as PriceProps } from 'models/money.interface';
import { formatPrice, hasDiscount } from 'utils/price';

interface ProductPriceProps {
  price: PriceProps|null;
  msrp?: PriceProps|null;
  isActive: Boolean;
  productId: string;
  variant?: CustomTypographyVariants;
}

const ProductPrice:FC<ProductPriceProps> = ({ price, msrp = null, isActive, productId, variant = 'subtitle' }) => {
  const hasProductDiscount = hasDiscount(msrp, price);
  const productPrice = formatPrice(price);

  return (
    <Crawler
      containerId={`price-container-crawler-${productId}`}
      elementId={isActive ? `price-crawler-${productId}` : ''}
      resetValue={24}
    >
      {({ duplicateText }) => (
        <Price variant={variant} fontWeight={700} id={isActive ? `price-crawler-${productId}` : ''}>
          {hasProductDiscount && (
            <StyledOldPrice>
              {formatPrice(msrp)}
            </StyledOldPrice>
          )}
          {productPrice}

          {duplicateText && (
            <>
              <span style={{ paddingRight: '48px' }} />

              {hasProductDiscount && (
                <StyledOldPrice>
                  {formatPrice(msrp)}
                </StyledOldPrice>
              )}
              {productPrice}
            </>
          )}
        </Price>
      )}
    </Crawler>
  );
};

export default ProductPrice;
