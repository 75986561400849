import { Box, Typography, useTheme } from '@mui/material';
import { CSSProperties, FC, useEffect, useMemo, useRef, useState } from 'react';

import { ProgressLabelWrapper } from 'components/CircularProgressWithLabel/index.styled';
import { useIntersection } from 'components/CircularProgressWithLabel/useIntersection';

import { useAnimatedValue } from 'hooks/useAnimatedValue';

import { CustomTypographyVariants } from 'assets/theme/theme-declarations';

interface CircularProgressWithLabelProps {
  label?: string;
  size?: number;
  variant?: CustomTypographyVariants;
  progress: number;
  showValue?: boolean;
  isEmpty?: boolean;
  range?: {
    from: number;
    to: number;
  }
  sx?: {
    loadingTime?: number;
    barWidth?: number;
    bgBarWidth?: number;
    bgStrokeColor?: string;
    strokeColor?: string;
    intersectionEnabled?: boolean;
  },
}

const rotate = 'rotate(-90, 55, 55)';
const ratio = 1;

export const progressThickness = 5;
export const placeholderThickness = 2;

const dasharray = 2 * Math.PI * 47;

const CircularProgressWithLabel:FC<CircularProgressWithLabelProps> = ({
  size = 230,
  progress = 0,
  range = { from: 0, to: 100 },
  label,
  showValue = false,
  isEmpty = false,
  sx = {},
  variant = 'h1',
}) => {
  const theme = useTheme();
  const {
    loadingTime = 1000,
    barWidth = 15,
    bgBarWidth = 5,
    intersectionEnabled = false,
    bgStrokeColor = theme.palette.general.lightGrey6,
    strokeColor = isEmpty ? theme.palette.general.lightGrey6 : theme.palette.success.light
  } = sx;

  const [afterProgress, setAfterProgress] = useState(0);
  const flatRef = useRef<HTMLDivElement>(null);
  const prevCountRef = useRef(0);
  const { isVisible } = useIntersection(flatRef);

  const { animatedValue } = useAnimatedValue(prevCountRef.current / ratio, afterProgress / ratio, loadingTime);

  useEffect(() => {
    if ((intersectionEnabled && isVisible) || !intersectionEnabled) {
      setAfterProgress(progress * ratio);
      prevCountRef.current = afterProgress;
    }
  }, [progress, isVisible, intersectionEnabled]);

  const dashoffset = useMemo(() => (
    (1 - (afterProgress + range.from) / range.to) * dasharray
  ), [afterProgress, range, dasharray]);

  const transitionDuration = useMemo(() => (loadingTime * 1.25).toString().concat('ms'), [loadingTime]);

  return (
    <Box position="relative" display="inline-flex" width={size}>
      <div ref={flatRef} style={{ position: 'relative', width: '100%', height: '100%' }}>
        <svg viewBox="0 0 110 110" style={{ position: 'relative', zIndex: 50 }}>
          <circle
            cx="55"
            cy="55"
            r="47"
            style={{
              transition: 'stroke-dashoffset ease-in-out',
              transitionDuration,
            }}
            strokeWidth={barWidth}
            transform={rotate}
            fill="none"
            stroke={strokeColor}
            shapeRendering="geometricPrecision"
            strokeLinecap="round"
            strokeDasharray={dasharray}
            strokeDashoffset={dashoffset}
          />
        </svg>
        <svg
          viewBox="0 0 110 110"
          style={{
            position: 'absolute',
            '--ds1': 'drop-shadow(0 10px 8px rgb(0 0 0 / 0.04))',
            '--ds2': 'drop-shadow(0 4px 3px rgb(0 0 0 / 0.1))',
            filter: 'var(--ds1) var(--ds2)',
            top: 0,
            display: 'block'
          } as CSSProperties}
        >
          <circle
            cx="55"
            cy="55"
            r="47"
            fill="none"
            stroke={bgStrokeColor}
            strokeWidth={bgBarWidth - 0.5}
            strokeDasharray={dasharray}
            strokeLinecap="round"
            strokeDashoffset={(1 - 100 / 100) * dasharray}
            transform={rotate}
            shapeRendering="geometricPrecision"
          />
        </svg>
        {showValue && (
          <ProgressLabelWrapper>
            <Typography fontWeight={400} textAlign="center" p={8} color="common.white" variant={variant}>
              {`${animatedValue}%`}
            </Typography>
          </ProgressLabelWrapper>
        )}
        {label && (
          <ProgressLabelWrapper>
            <Typography fontWeight={400} textAlign="center" p={8} color="common.white" variant={variant}>
              {label}
            </Typography>
          </ProgressLabelWrapper>
        )}
      </div>
    </Box>
  );
};

export default CircularProgressWithLabel;
