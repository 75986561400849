import { createSelector } from '@reduxjs/toolkit';

import { getCampaignCurrencyCode } from 'store/campaign/utils';
import { RootState } from 'store/index';

import { ApiStatus, BountyState, PointCurrency } from 'constants/enums';
import { Bounty } from 'models/bounty.interface';
import { CampaignContent } from 'models/campaign.interface';
import { CustomCurrencyStats } from 'models/money.interface';
import { sortBounties } from 'utils/bounty';
import { getPointsAmount } from 'utils/point';

export const isViewedSelector = (state: RootState): boolean => state.campaign?.isViewed;
export const campaignSelector = (state: RootState): CampaignContent|undefined => state.campaign?.campaign;
export const featuredCampaignSelector = (state: RootState): CampaignContent|undefined => (
  state.campaign?.featuredCampaign?.data
);
export const featuredCampaignLoadingSelector = (state: RootState): boolean => (
  state.campaign?.featuredCampaign?.status === ApiStatus.loading
);

export const campaignDetailsSelector = (state: RootState): Bounty|undefined => state.campaign?.campaignDetails;
export const campaignErrorSelector = (state: RootState): Bounty|undefined => state.campaign?.error;
export const campaignBountiesSelector = (state: RootState): Bounty[]|undefined => state.campaign?.campaign?.subBounties;
export const isLoadingSelector = (state: RootState): boolean => state.campaign?.status === ApiStatus.loading;
export const refreshingCampaignSelector = (state: RootState): boolean => (
  state.campaign?.refreshStatus === ApiStatus.loading
);
export const viewCampaignsSelector = (state: RootState): boolean => state.campaign?.viewCampaign || false;
export const storiesEndedSelector = (state: RootState): boolean => state.campaign?.storiesEnded || false;
export const isPlayingTriviaSelector = (state: RootState): boolean => state.campaign?.isPlayingTrivia || false;
export const highlightNextButtonSelector = (state: RootState): boolean => state.campaign?.highlightNextButton || false;
export const highlightShopButtonSelector = (state: RootState): boolean => state.campaign?.highlightShopButton || false;

export const includesTokensSelector = createSelector(
  campaignBountiesSelector,
  (bounties) => {
    const bounty = bounties?.find(({ reward }) => !!reward?.points);
    return Boolean(bounty);
  },
);

export const campaignTokensSelector = createSelector(
  campaignBountiesSelector,
  (bounties) => {
    let amount = 0;
    bounties?.forEach(({ reward }) => {
      if (reward?.points) {
        amount += getPointsAmount(reward, PointCurrency.Systkn);
      }
    });

    return amount;
  },
);

export const campaignCurrencySelector = createSelector(
  campaignSelector,
  (campaign) => getCampaignCurrencyCode(campaign),
);

export const campaignOrderedBountiesSelector = createSelector(
  campaignBountiesSelector,
  (bounties) => (bounties ? sortBounties(bounties) : []),
);

export const campaignColorsSelector = (state: RootState): { val: string }[]|undefined => (
  state.campaign?.campaign?.bounty?.theme?.colorScheme?.colors
);

export const currencyDetailsSelector = (state: RootState): CustomCurrencyStats|undefined => (
  state.campaign?.currency?.data?.meta
);

const getBounties = (state: RootState) => state.campaign.bounties;

export const bountyDetailsSelector = createSelector(
  (_: RootState, bounty: Bounty) => bounty,
  getBounties,
  (bounty: Bounty, bounties?: Record<string, Bounty>) => (bounties ? bounties[bounty.id] : bounty),
);

const getTriviaBounties = (state: RootState) => state.campaign.triviaBounties;

export const triviaSubBountiesSelector = createSelector(
  (_: RootState, bountyId: string) => bountyId,
  getTriviaBounties,
  (bountyId: string, bounties?: Record<string, Bounty[]>) => (bounties ? bounties?.[bountyId] : []),
);

export const triviaSubBountyDetailsSelector = createSelector(
  (_: RootState, bounty: Bounty) => bounty,
  getTriviaBounties,
  (bounty: Bounty, bounties?: Record<string, Bounty[]>) => {
    const parentId = bounty?.parentBounty?.id;

    if (parentId && bounties && bounties[parentId]) {
      return bounties[parentId]?.find(({ id }) => id === bounty.id) || null;
    }

    return null;
  },
);

const closedStates = [
  BountyState.Rejected,
  BountyState.Draft,
  BountyState.Expired,
  BountyState.Retracted,
];

export const hasFeaturedCampaignSelector = createSelector(
  featuredCampaignSelector,
  (closingCampaign) => Boolean(
    closingCampaign?.bounty?.state
    && !closedStates.includes(closingCampaign?.bounty?.state)
  ),
);

export const hasFeaturedActivitySelector = createSelector(
  campaignDetailsSelector,
  hasFeaturedCampaignSelector,
  (campaign: Bounty|undefined, hasClosingCampaign) => {
    if (hasClosingCampaign) {
      return true;
    }

    return Boolean(campaign?.activities?.highlightedActivities
      && Object.keys(campaign.activities?.highlightedActivities).length > 0
    );
  },
);

export const shouldFetchNextCampaignSelector = createSelector(
  campaignDetailsSelector,
  featuredCampaignSelector,
  (campaign: Bounty|undefined, featuredCampaign) => {
    if (featuredCampaign?.bounty) {
      return false;
    }

    return Boolean(campaign?.activities?.nextActivity);
  },
);
