import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { useIntl } from 'react-intl';

import { progressThickness } from 'components/CircularProgressWithLabel';
import { AvatarWrapper, RadarAnimation, RadarWrapper } from 'components/FollowUserAvatar/index.styled';
import UserAvatar from 'components/UserAvatar';

import { CustomTypographyVariants } from 'assets/theme/theme-declarations';
import { User } from 'models/user.interface';
import { getUsernameInitials } from 'utils/user';

interface FollowUserAvatarProps {
  progressWidth: number;
  variant: CustomTypographyVariants;
  user?: User;
}

const FollowUserAvatar:FC<FollowUserAvatarProps> = ({ progressWidth, variant, user }) => {
  const intl = useIntl();
  const thicknessWidth = Math.round((progressThickness / 100) * progressWidth);
  const avatarSize = progressWidth - (thicknessWidth * progressThickness);

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <AvatarWrapper width={progressWidth}>
        <UserAvatar
          width={avatarSize}
          height={avatarSize}
          src={user?.avatar}
          userInitials={getUsernameInitials(user?.name)}
          sx={{ zIndex: 1000, fontSize: `${thicknessWidth * 6}px` }}
         />
        <RadarWrapper>
          <RadarAnimation />
        </RadarWrapper>
      </AvatarWrapper>
      <Box my={2}>
        <Typography fontWeight={400} variant={variant} textAlign="center" color="common.white">
          {intl.formatMessage({ id: 'label.following' })}
        </Typography>
        <Typography fontWeight={400} variant={variant} textAlign="center" color="common.white">
          {user?.name}
        </Typography>
      </Box>
    </Box>
  );
};

export default FollowUserAvatar;
