import { FC, ReactNode } from 'react';

import { AvatarWrapper, Banner } from 'components/UserBanner/index.styled';

interface UserBannerProps {
  children: ReactNode;
  src?: string;
}

const UserBanner:FC<UserBannerProps> = ({ children, src }) => (
  <Banner src={src}>
    <AvatarWrapper>
      {children}
    </AvatarWrapper>
  </Banner>
);

export default UserBanner;
