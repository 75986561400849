import { Box, Typography, Button } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { Action } from 'react-insta-stories/dist/interfaces';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import LoadingStory from 'components/LoadingStory';
import CampaignDetails from 'containers/SeriesDetails/CampaignDetails';
import { Divider } from 'containers/SeriesDetails/index.styled';
import SigninInvitation from 'containers/SigninInvitation';
import { FbUserContext } from 'context/FbUserContext';
import SeriesLayout from 'layouts/SeriesLayout';

import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { getBountyActivitiesChain } from 'services/Bounty';
import { getUserEarnings } from 'services/User';
import {
  seriesActiveBountyIdSelector,
  showSeriesDetailsSelector,
} from 'store/series/selectors';
import { setShowDetails, setShowRanking } from 'store/series/seriesSlice';

import ArrowBack from 'assets/icons/ArrowBack';
import ArrowForward from 'assets/icons/ArrowForward';
import { SERIES_RANKINGS_ROUTE } from 'constants/clientRoutes';
import { Bounty } from 'models/bounty.interface';
import { Earnings } from 'models/earnings.interface';
import { formatRoute } from 'utils/formatters';

import { RoundedButton } from './index.styled';

interface SeriesDetailsProps {
  isOverlay?: boolean;
  onAction?: Action;
}

const SeriesDetails: FC<SeriesDetailsProps> = ({ isOverlay = false, onAction }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { campaignId = '' } = useParams();
  const { fbUser } = useContext(FbUserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingEarnings, setIsLoadingEarnings] = useState(false);
  const showSeriesDetails = useAppSelector(showSeriesDetailsSelector);
  const activeBountyId = useAppSelector(seriesActiveBountyIdSelector);
  const [campaigns, setCampaigns] = useState<Bounty[]>([]);
  const [earnings, setEarnings] = useState<Record<string, Earnings>>({});
  const entityId = isOverlay ? activeBountyId : campaignId;
  
  useEffect(() => {
    if (entityId) {
      fetchActivitiesChain();
    }
  }, [entityId]);

  useEffect(() => {
    if (fbUser?.uid && campaigns?.length > 0) {
      fetchEarnings();
    }
  }, [fbUser?.uid, campaigns]);

  const fetchActivitiesChain = async () => {
    setIsLoading(true);

    try {
      const response = await getBountyActivitiesChain(entityId);
      setCampaigns(response?.data?.list || []);
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchEarnings = async () => {
    if (!fbUser?.uid) {
      toast.error(intl.formatMessage({ id: 'error.missingUserId' }));
      return;
    }

    setIsLoadingEarnings(true);

    try {
      const bountyIds = campaigns?.map(({ id }) => id);
      const filters = new URLSearchParams();
      filters.set('bounty_id', bountyIds?.join(','));

      const response = await getUserEarnings(fbUser?.uid, filters);

      setEarnings(response?.data?.bountyEarnings);
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
      }
    } finally {
      setIsLoadingEarnings(false);
    }
  };

  const handleSeeRankings = () => {
    if (showSeriesDetails) {
      dispatch(setShowRanking({ isVisible: true, bountyId: campaignId }));
      // dispatch(setShowDetails({ isVisible: false, bountyId: '' }));
      return;
    }

    navigate(formatRoute(SERIES_RANKINGS_ROUTE, { campaignId }));
  };

  const handleBack = () => {
    dispatch(setShowDetails({ isVisible: false, bountyId: '' }));

    if (onAction) {
      onAction('play');
    }
  };

  if (!isOverlay && isLoading) {
    return <LoadingStory />;
  }

  return (
    <SeriesLayout isVisible={showSeriesDetails} isOverlay={isOverlay} isLoading={isLoading}>
      {isOverlay && (
        <RoundedButton
          variant="outlinedDark"
          onClick={handleBack}
          startIcon={<ArrowBack />}
        >
          <Typography variant="body2">
            {intl.formatMessage({ id: 'button.back' })}
          </Typography>
        </RoundedButton>
      )}
      {campaigns?.map((campaign) => (
        <Box key={campaign?.id}>
          <CampaignDetails
            bounty={campaign}
            earnings={earnings?.[campaign?.id] || null}
            isLoadingEarnings={isLoadingEarnings}
          />
          <Divider sx={{ my: 2.5 }} />
        </Box>
      ))}
      <Button
        variant="outlinedDark"
        onClick={handleSeeRankings}
        endIcon={<ArrowForward />}
        fullWidth
        sx={{ p: 2 }}
      >
        <Typography variant="subtitle" fontWeight={600}>
          {intl.formatMessage({ id: 'button.seeRankings' })}
        </Typography>
      </Button>
      <SigninInvitation />
    </SeriesLayout>
  );
};

export default SeriesDetails;
