import { alpha, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StoryOverlay = styled(
  Box,
  { shouldForwardProp: (props) => !['height', 'opacity'].includes(props as string) },
)<{ height: number, opacity?: number }>(({ theme, height, opacity = 0.9 }) => ({
  position: 'absolute',
  top: '0px',
  left: '0px',
  width: '100%',
  height: `${height}px`,
  backgroundColor: alpha(theme.palette.common.black, opacity) ,
  zIndex: 1001,
  borderRadius: '9px',
  padding: theme.spacing(8, 4),
  overflow: 'auto',
}));
