import { createBrowserRouter } from 'react-router-dom';

import ErrorBoundary from 'components/_ErrorBoundary';
import IconsList from 'components/IconsList';
import StoriesView from 'containers/CampaignDetails/index.page';
import Home from 'containers/Home/index.page';
import Logout from 'containers/Logout';
import MerchantProfile from 'containers/MerchantProfile';
import SeriesDetails from 'containers/SeriesDetails';
import SeriesRankings from 'containers/SeriesRankings';
import VisitApp from 'containers/VisitApp';
import CampaignViewerLayout from 'layouts/CampaignViewerLayout';
import SuspenseLayout from 'layouts/SuspenseLayout';

import * as routes from 'constants/clientRoutes';

const router = createBrowserRouter([
  {
    element: <CampaignViewerLayout />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: routes.CAMPAIGN_VIEW,
        element: <StoriesView />,
      },
      {
        path: routes.VISIT_APP,
        element: <VisitApp />,
      },
      {
        path: routes.SERIES_DETAILS_ROUTE,
        element: <SeriesDetails />,
      },
      {
        path: routes.SERIES_RANKINGS_ROUTE,
        element: <SeriesRankings />,
      },
      {
        path: routes.USER_DETAILS_ROUTE,
        element: <MerchantProfile />,
      },
    ],
  },
  {
    path: routes.ICONS_ROUTE,
    element: <IconsList />,
  },
  {
    path: '/logout',
    element: <Logout />,
  },
  {
    element: <SuspenseLayout />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        element: <Home />,
        path: '*',
      },
    ],
  },
]);

export default router;
