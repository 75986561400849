import { useRef, useState, createContext, ReactNode, FC, useMemo } from 'react';

import confettiPopping from 'assets/audio/confetti-popping.mp3';
interface SoundDispatchContext {
  loadSounds: () => void;
  playConfetti: () => void;
}
const SoundDispatchContext = createContext<SoundDispatchContext>({
  loadSounds: () => null,
  playConfetti: () => null,
});
interface SoundProviderProps {
  children: ReactNode;
}
const SoundProvider:FC<SoundProviderProps> = ({ children }) => {
  const confettiRef = useRef(new Audio(confettiPopping));
  const [isPreloaded, setIsPreloaded] = useState(false);
  const loadSounds = () => {
    if (!isPreloaded) {
      confettiRef?.current?.load();
      setIsPreloaded(true);
    }
  };
  const playConfetti = () => {
    if (isPreloaded && confettiRef?.current) {
      confettiRef
        .current
        .play()
        .catch((error) => {
          console.error('Playback failed:', error);
        });
    }
  };
  const dispatchContextStore = useMemo(() => ({
    loadSounds,
    playConfetti,
  }), [confettiRef, isPreloaded]);
  return (
    <SoundDispatchContext.Provider value={dispatchContextStore}>
      {children}
    </SoundDispatchContext.Provider>
  );
};
export {
  SoundProvider,
  SoundDispatchContext
};
