import { Skeleton } from '@mui/material';

import { Root } from 'containers/MerchantProfile/ProductCard/index.styled';

const ProductCardSkeleton = () => (
  <Root>
    <Skeleton className="skeletonImage" animation="wave" variant="rounded" width="100%" />

    <Skeleton animation="wave" variant="text" width="40%" height={32} />
    <Skeleton animation="wave" variant="text" width="100%" height={32} />
  </Root>
  );

export default ProductCardSkeleton;
