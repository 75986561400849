import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

export const FollowButton = styled(
  Button,
  { shouldForwardProp: (props) => props !== 'isFollowing' },
)<{ isFollowing: boolean }>(({ theme, isFollowing }) => ({
  position: 'absolute',
  top: theme.spacing(-5.5),
  padding: theme.spacing(2, 4),
  right: theme.spacing(2.5),
  borderRadius: '22px',
  height: '42px',
  fontSize: '18px',
  border: `1px solid ${theme.palette.common.black}`,
  ...(isFollowing ? {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.black,
  } : {
    color: theme.palette.common.black,
    backgroundColor: theme.palette.common.white,
  })
}));
