import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';

import NotificationContainer from 'components/NotificationContainer';
import { FbUserProvider } from 'context/FbUserContext';
import { SoundProvider } from 'context/SoundContext';
import router from 'routes/main';

import { store } from 'store/index';

import appTheme from 'assets/theme/theme';
import { DEFAULT_LANGUAGE } from 'constants/general';
import AppLocale from 'languages/index';

import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  const defaultLang = AppLocale[DEFAULT_LANGUAGE];

  return (
    <Provider store={store}>
      <IntlProvider
        locale={defaultLang.locale}
        defaultLocale={defaultLang.locale}
        messages={defaultLang.messages}
      >
        <ThemeProvider theme={appTheme}>
          <StyledEngineProvider injectFirst>
            {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
            <CssBaseline />
            <FbUserProvider>
              <SoundProvider>
                <RouterProvider router={router} />
              </SoundProvider>
            </FbUserProvider>
            <NotificationContainer />
          </StyledEngineProvider>
        </ThemeProvider>
      </IntlProvider>
    </Provider>
  );
};

export default App;
