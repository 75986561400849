export const DEFAULT_LANGUAGE = 'en-US';

export const TIMESTAMP_MILLISECONDS = 1000;
export const ALLOWED_FILE_SIZE = 10485760;
export const CAMPAIGN_APP = 'Campaign';

export const ACCEPTED_IMAGE_TYPES = {
  'image/jpeg': ['.jpeg'],
  'image/png': ['.png'],
  'image/jpg': ['.jpg'],
};

export const paginationItemsPerPage = 10;
