import { styled } from '@mui/material/styles';

export const CrawlerContainer = styled('div')(() => ({
  width: '100%',
  height: '26px',
  maxWidth: '100%',
  overflow: 'hidden',
  position: 'relative',
  borderRadius: '8px',
}));

export const CrawlerTextWrapper = styled('div')(() => ({
  display: 'flex',
  position: 'absolute',
  whiteSpace: 'no-wrap',
}));
