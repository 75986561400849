const MuiTabs = {
  styleOverrides: {
    root: () => ({
      alignSelf: 'end',
      minHeight: '22px',
    }),
    indicator: () => ({
      display: 'none',
    }),
  },
};

export default MuiTabs;
