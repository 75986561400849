export const GET_OPEN_BOUNTY_BY_ID_ROUTE = 'api/v1/open/bounties/:bountyId/info';
export const REQUESTS_ROUTE = 'api/v1/requests/:action';
export const EVENTS_ROUTE = 'api/v1/events/:action';
export const PARTICIPATE_ROUTE = 'api/v1/bounties/:campaignId/participate';
export const CURRENCY_DETAILS_ROUTE = 'api/v1/open/currency/:code';
export const BOUNTY_BUDGET_ROUTE = 'api/v1/open/bounties/:bountyId/budget';
export const BOUNTY_ACTIVITIES_CHAIN_ROUTE = 'api/v1/open/bounties/:bountyId/activities/chain';
export const GET_BOUNTY_LEADERBOARD_ROUTE = 'api/v1/entities/:bountyId/leaderboard';
export const NOTIFY_ON_START_ROUTE = 'api/v1/bounties/:bountyId/notifyOnStart';
export const OPEN_LIKE_ENTITY_ROUTE = 'api/v1/open/:entityType/:entityId/like';
export const OPEN_SAVE_RESPONSE_ROUTE = 'api/v1/open/bounty/:bountyId/response';
export const UPDATE_USER_INFO_API = '/api/v1/users/:userId/update_user';
export const ACCOUNT_AVAILABLE_AMOUNT = '/api/v1/account/amount_tmp';
export const USER_PROFILE_API = '/api/v1/users/:userId/profile';
export const USER_CONFIRMATION_API = 'api/v1/users/:userId/confirmation';
export const USER_EARNINGS_API = 'api/v1/open/users/:userId/earnings';
export const QUICK_CLAIM_API = 'api/v1/open/bounty/:bountyId/quick_claim';
export const ACCOUNT_CURRENCIES_API = 'api/v1/account/currencies';
export const OPEN_USER_PROFILE_ROUTE = 'api/v1/open/users/:userId/profile';
export const OPEN_CAMPAIGNS_ROUTE = 'api/v1/open/campaigns';
