import { Skeleton, Typography } from '@mui/material';
import { FC } from 'react';

interface DescriptionProps {
  isLoading: boolean;
  description?: string;
}

const Description:FC<DescriptionProps> = ({ isLoading, description }) => (
  isLoading
    ? <Skeleton variant="text" height="52px" width="300px" animation="wave" />
    : (
      <Typography variant="subtitle1">
        {description}
      </Typography>
    )
);

export default Description;
