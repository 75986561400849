import { styled } from '@mui/material/styles';

export const Page = styled('div')(() => ({
  position: 'relative',
  width: '100%',
  height: '100%',
}));

export const Root = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  borderRadius: '10px',
  backgroundColor: theme.palette.general.darkGrey4,
  color: theme.palette.text.primary,
}));

export const Content = styled('div')(({ theme }) => ({
  height: 'calc(100vh - 420px)',
  padding: theme.spacing(2.5)
}));
