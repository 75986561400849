import { alpha } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Root = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: '30px',
  padding: '8px',
  backgroundColor: alpha(theme.palette.common.black, 0.6),
  color: theme.palette.common.white,
}));
