import { runGetApiRequest } from 'services/Api';

import { REQUESTS_ROUTE } from 'constants/apiRoutes';
import { MarketMode } from 'constants/enums';
import { paginationItemsPerPage } from 'constants/general';
import { QueueActions } from 'constants/queueActions';
import { formatRoute } from 'utils/formatters';

export const getCampaignCreatorOneProduct = (userId: string) => {
  const filters = {
    listId: 'PRODUCT',
    start: 0,
    length: 1,
    filter: JSON.stringify({
      marketMode: MarketMode.UserInternal,
      creatorId: userId,
    }),
  };
  const endpoint = formatRoute(REQUESTS_ROUTE, { action: QueueActions.GetList });
  return runGetApiRequest(endpoint, filters);
};

export const getProducts = (userId: string, page: number) => {
  const filters = {
    listId: 'PRODUCT',
    start: page,
    length: paginationItemsPerPage,
    filter: JSON.stringify({
      marketMode: MarketMode.UserInternal,
      creatorId: userId,
    }),
  };
  const endpoint = formatRoute(REQUESTS_ROUTE, { action: QueueActions.GetList });
  return runGetApiRequest(endpoint, filters);
};
