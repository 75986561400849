import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface SeriesState {
  showDetails: boolean;
  showRanking: boolean;
  activeBountyId: string;
}

export const initialState: SeriesState = {
  showDetails: false,
  showRanking: false,
  activeBountyId: '',
};

export const seriesSlice = createSlice({
  name: 'series',
  initialState,
  reducers: {
    setShowDetails: (state, action: PayloadAction<{ isVisible: boolean; bountyId: string }>) => {
      state.showDetails = action.payload.isVisible;
      state.activeBountyId = action.payload.bountyId;
      return state;
    },
    setShowRanking: (state, action: PayloadAction<{ isVisible: boolean; bountyId: string }>) => {
      state.showRanking = action.payload.isVisible;
      state.activeBountyId = action.payload.bountyId;
      return state;
    },
  },
});

export const {
  setShowDetails,
  setShowRanking,
} = seriesSlice.actions;

export default seriesSlice.reducer;
