import { styled } from '@mui/material/styles';

export const ProgressLabelWrapper = styled('div')(() => ({
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
