import { runGetApiRequest, runPostApiRequest } from 'services/Api';

import { BOUNTY_BUDGET_ROUTE, OPEN_CAMPAIGNS_ROUTE, PARTICIPATE_ROUTE } from 'constants/apiRoutes';
import { paginationItemsPerPage } from 'constants/general';
import { formatRoute } from 'utils/formatters';

export const participateToCampaign = (campaignId: string) => (
  runPostApiRequest(formatRoute(PARTICIPATE_ROUTE, { campaignId }))
);

export const getCampaignBudget = (campaignId: string) => (
  runGetApiRequest(formatRoute(BOUNTY_BUDGET_ROUTE, { bountyId: campaignId }))
);

export const getUserCampaigns = (userId: string, page: number) => {
  const filters = {
    start: page,
    length: paginationItemsPerPage,
    creatorId: userId,
  };
  return runPostApiRequest(OPEN_CAMPAIGNS_ROUTE, filters);
};
