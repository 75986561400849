import { AxiosResponse } from 'axios';
import { remove, set } from 'firebase/database';

import { runGetApiRequest, runPostApiRequest, runUpdateApiRequest } from 'services/Api';
import { userSocialActionStateRef } from 'services/FirebaseRefs';

import {
  EVENTS_ROUTE, OPEN_USER_PROFILE_ROUTE,
  UPDATE_USER_INFO_API,
  USER_CONFIRMATION_API,
  USER_EARNINGS_API,
  USER_PROFILE_API
} from 'constants/apiRoutes';
import { ConfirmationType } from 'constants/enums';
import { QueueActions } from 'constants/queueActions';
import { UserEarnings } from 'models/earnings.interface';
import { UserSocialData } from 'models/user.interface';
import { formatRoute } from 'utils/formatters';

export const updateUserInfo = async (userId: string, user: any) => {
  const endpoint = formatRoute(UPDATE_USER_INFO_API, { userId });
  return runUpdateApiRequest(endpoint, user);
};

export const followUser = async (userId: string, follow: boolean) => {
  const endpoint = formatRoute(EVENTS_ROUTE, { action: QueueActions.FollowUser });
  return runPostApiRequest(endpoint, {
    userId,
    follow,
  });
};

export const updateSocialActionState = async (
  loggedUserId: string,
  userId: string,
  socialActionType: string,
  userSocial: UserSocialData|null,
) => {
  const ref = userSocialActionStateRef(loggedUserId, userId, socialActionType);

  if (userSocial) {
    return set(ref, userSocial);
  }

  return remove(ref);
};

export const getUserProfile = async (userId: string) => {
  const endpoint = formatRoute(USER_PROFILE_API, { userId });
  return runGetApiRequest(endpoint);
};

export const updateUserHeadsUpTimerConfirmation = async (userId: string) => {
  const endpoint = formatRoute(USER_CONFIRMATION_API, { userId });
  const confirmation = {
    type: ConfirmationType.HideLetsGoDialog,
    confirmed: true,
    confirmedAt: Date.now(),
  };
  return runPostApiRequest(endpoint, confirmation);
};

export const updateUserRewardHint = async (userId: string, type: ConfirmationType) => {
  const endpoint = formatRoute(USER_CONFIRMATION_API, { userId });
  const confirmation = {
    type,
    confirmed: true,
    confirmedAt: Date.now(),
  };
  return runPostApiRequest(endpoint, confirmation);
};

export const updateChallengeRepliesHint = async (userId: string) => {
  const endpoint = formatRoute(USER_CONFIRMATION_API, { userId });
  const confirmation = {
    type: ConfirmationType.HideChallengeRepliesHint,
    confirmed: true,
    confirmedAt: Date.now(),
  };
  return runPostApiRequest(endpoint, confirmation);
};

export const getUserEarnings = async (
  userId: string,
  filters: URLSearchParams
): Promise<AxiosResponse<UserEarnings>> => {
  const endpoint = formatRoute(USER_EARNINGS_API, { userId });
  return runGetApiRequest(endpoint, filters);
};

export const getOpenUserProfile = async (userId: string) => {
  const endpoint = formatRoute(OPEN_USER_PROFILE_ROUTE, { userId });
  return runGetApiRequest(endpoint);
};
