import { FC } from 'react';
import { useIntl } from 'react-intl';

import FollowUserAvatar from 'components/FollowUserAvatar';
import SigninInvitation from 'containers/SigninInvitation';
import FollowUserLayout from 'layouts/FollowUserLayout';

import useStoryDimensions from 'hooks/useStoryDimensions';

import { User } from 'models/user.interface';

interface FollowMerchantOverlayProps {
  user?: User;
}

const FollowMerchantOverlay:FC<FollowMerchantOverlayProps> = ({ user }) => {
  const intl = useIntl();
  const { width } = useStoryDimensions();
  const progressWidth = Math.round(width / 2);

  const renderBr = () => <br key="br" />;

  return (
    <FollowUserLayout
      title={intl.formatMessage({ id: 'label.saveYourFollow' }, { br: renderBr })}
      submitLabel={intl.formatMessage({ id: 'button.signupToSaveFollow' })}
    >
      <FollowUserAvatar
        user={user}
        progressWidth={progressWidth}
        variant="title1"
      />
      <SigninInvitation />
    </FollowUserLayout>
  );
};

export default FollowMerchantOverlay;
