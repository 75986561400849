import { styled } from '@mui/material/styles';

export const Root = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  backgroundColor: theme.palette.common.black,
  borderRadius: '10px',
  padding: theme.spacing(2.5),
}));

export const LoadingWrapper = styled('div')<{ height: number }>(({ theme, height }) => ({
  position: 'absolute',
  top: '0px',
  width: '100%',
  height: `${height}px`,
  zIndex: 1001,
  borderRadius: '9px',
  overflow: 'auto',
  backgroundColor: theme.palette.background.main,
}));
