import flavourConfig from 'configuration/env';

export const getEnv = () => {
  const { env } = flavourConfig;

  const { hostname } = window.location;

  if (hostname.includes('localhost')) {
    return 'local';
  }
  if (hostname.includes('--test-')) {
    return 'test';
  }
  return env;
};

export const enableSentry = () => isProdEnv() || ['test'].includes(getEnv());
export const isProdEnv = () => process.env.NODE_ENV === 'production' && getEnv() === 'prod';

export const isTestChannel = () => ['local', 'test'].includes(getEnv());

export const isWebView = () => {
  const userAgent = window.navigator.userAgent;
  const webViewIndicators = /TikTok|FBAN|FBAV|Instagram|WKWebView/i.test(userAgent);

  if (webViewIndicators) {
    return true;
  }

  if (isAndroid()) {
    return userAgent.toLowerCase().includes('webview');
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const standalone = window.navigator?.standalone;
  const safari = /safari/.test(userAgent.toLowerCase());
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const isReactNative = !!window.ReactNativeWebView;

  return isReactNative || (!standalone && !safari);
};

export const isAndroid = () => /Android/i.test(window.navigator.userAgent);

export const isMobile = () => /Mobi|Android|iPhone|iPad|iPod|Windows Phone/i.test(navigator.userAgent);
