import { Skeleton, Box } from '@mui/material';
import { FC, useMemo } from 'react';

import UserName from 'components/UserName';

import VerifiedOutlined from 'assets/icons/VerifiedOutlined';
import { UserReputation } from 'constants/enums';
import { reputationBuilder } from 'utils/user';

interface NameProps {
  isLoading: boolean;
  name?: string;
  reputation?: string;
}

const Name:FC<NameProps> = ({ isLoading, name, reputation }) => {
  const isVerified = useMemo(() => (
    reputation === UserReputation.Verified || reputationBuilder(reputation)?.icon === 'verified'
  ), [reputation]);

  return (
    <Box display="flex" alignItems="center" gap={1} mb={1}>
      {isLoading
        ? <Skeleton variant="text" height="52px" width="300px" animation="wave" />
        : (
          <>
            <UserName name={name || ''} fontWeight={700} variant="title1" />
            {isVerified && (
              <VerifiedOutlined size={26} />
            )}
          </>
        )}
    </Box>
  );
};

export default Name;
