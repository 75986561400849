import { SxProps } from '@mui/material';
import { FC, ReactElement, ReactNode } from 'react';

import { StyledAvatar } from 'components/UserAvatar/index.styled';

interface UserAvatarProps {
  src?: string|null;
  userInitials?: string;
  height?: string | number;
  width?: string | number;
  opacity?: number;
  variant?: 'rounded' | 'circular' | 'square';
  defaultIcon?: ReactNode;
  sx?: SxProps;
}

const UserAvatar: FC<UserAvatarProps> = ({
  src = '',
  userInitials,
  height = '30px',
  width = '30px',
  variant = 'circular',
  defaultIcon,
                                           opacity = 0.7,
  sx = {},
}): ReactElement => (
  <StyledAvatar opacity={opacity} sx={{ height, width, ...sx }} variant={variant} src={src || ''}>
    {defaultIcon || userInitials}
  </StyledAvatar>
);

export default UserAvatar;
