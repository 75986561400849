import { runGetApiRequest, runPostApiRequest } from 'services/Api';

import {
  BOUNTY_ACTIVITIES_CHAIN_ROUTE,
  EVENTS_ROUTE,
  GET_BOUNTY_LEADERBOARD_ROUTE,
  GET_OPEN_BOUNTY_BY_ID_ROUTE,
  NOTIFY_ON_START_ROUTE,
  OPEN_LIKE_ENTITY_ROUTE,
  OPEN_SAVE_RESPONSE_ROUTE, QUICK_CLAIM_API,
  REQUESTS_ROUTE
} from 'constants/apiRoutes';
import { EntityType, ReportReasonTypes } from 'constants/enums';
import { QueueActions } from 'constants/queueActions';
import { BountyResponse } from 'models/bountyResponse.interface';
import { Comment } from 'models/comment.interface';
import { getRequestMeta } from 'utils/api';
import { formatRoute } from 'utils/formatters';

export const getBountyById = (bountyId: string) => (
  runGetApiRequest(formatRoute(GET_OPEN_BOUNTY_BY_ID_ROUTE, { bountyId }))
);
 
export const getBountyLeaderboard = (
  bountyId: string,
  // eslint-disable-next-line camelcase
  queryParams: { exclude_anon: boolean, include_next_activities?: boolean } = { exclude_anon: false }
) => (
  runGetApiRequest(formatRoute(GET_BOUNTY_LEADERBOARD_ROUTE, { bountyId }), queryParams)
);

export const setNotifyMe = async (bountyId: string) => {
  const endpoint = formatRoute(NOTIFY_ON_START_ROUTE, { bountyId });
  return runPostApiRequest(endpoint);
};

export const reportBounty = async (bountyId: string, reportContentType: ReportReasonTypes, userId: string) => {
  const endpoint = formatRoute(EVENTS_ROUTE, { action: QueueActions.ReportBounty });

  return runPostApiRequest(endpoint, {
    bountyId,
    reportContentType,
    meta: getRequestMeta(QueueActions.ReportBounty, userId),
  });
};

export const anonLikeBounty = (entityId: string, like: boolean) => {
  const endpoint = formatRoute(OPEN_LIKE_ENTITY_ROUTE, { entityType: EntityType.Bounty, entityId });
  return runPostApiRequest(endpoint, { like });
};

export const anonSaveResponse = (bountyId: string, payload: Partial<BountyResponse>) => {
  const endpoint = formatRoute(OPEN_SAVE_RESPONSE_ROUTE, { bountyId });
  return runPostApiRequest(endpoint, payload);
};

export const rejectBounty = async (
  bountyId: string,
  userId: string,
  reason: string,
) => {
  const endpoint = formatRoute(REQUESTS_ROUTE, { action: QueueActions.RejectBounty });

  return runPostApiRequest(endpoint, {
    bountyId,
    reason,
    meta: getRequestMeta(QueueActions.RejectBounty, userId),
  });
};

export const countViews = async (bountyId: string, userId: string) => {
  const endpoint = formatRoute(EVENTS_ROUTE, { action: QueueActions.UserAction });
  const now = new Date().getTime();

  return runPostApiRequest(endpoint, {
    objectId: bountyId,
    objectType: 'BOUNTY',
    actedAt: now,
    duration: 0,
    verb: 'V',
    meta: getRequestMeta(QueueActions.UserAction, userId),
  });
};

export const addCommentToBounty = async (comment: Partial<Comment>, commentId: string, userId: string) => (
  runPostApiRequest(formatRoute(EVENTS_ROUTE, { action: QueueActions.AddComment }), {
    comment,
    commentId,
    meta: getRequestMeta(QueueActions.AddComment, userId),
  })
);

export const removeBountyComment = async (commentId: string, userId: string) => (
  runPostApiRequest(formatRoute(REQUESTS_ROUTE, { action: QueueActions.DeleteComment }), {
    commentId,
    meta: getRequestMeta(QueueActions.DeleteComment, userId),
  })
);

export const editBountyComment = async (comment: Comment, userId: string) => (
  runPostApiRequest(formatRoute(REQUESTS_ROUTE, { action: QueueActions.EditComment }), {
    id: comment.id,
    commentId: comment.id,
    text: comment.text,
    meta: getRequestMeta(QueueActions.EditComment, userId),
  })
);

export const getBountyActivitiesChain = (campaignId: string) => (
  runGetApiRequest(formatRoute(BOUNTY_ACTIVITIES_CHAIN_ROUTE, { bountyId: campaignId }))
);

export const quickClaim = (bountyId: string) => (
  runPostApiRequest(formatRoute(QUICK_CLAIM_API, { bountyId }), { type: 'CLAIM' })
);
