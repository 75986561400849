import { FC, ReactNode, useEffect, useState } from 'react';

import { CrawlerContainer, CrawlerTextWrapper } from 'components/Crawler/index.styled';

interface CrawlerProps {
  containerId: string;
  elementId: string;
  children: ({ duplicateText }: { duplicateText: boolean }) => ReactNode;
  resetValue: number;
}

const transition = 0.6;

const Crawler:FC<CrawlerProps> = ({ children, containerId, elementId, resetValue }) => {
  const [posX, setPosX] = useState(transition);
  const [duplicateText, setDuplicateText] = useState(false);
  const [wait, setWait] = useState(false);

  const moveCrawler = () => {
    const crawler = document.getElementById(elementId);
    const container = document.getElementById(containerId);

    if (wait) {
      return;
    }

    if (crawler && container && crawler.offsetWidth > container.offsetWidth) {
      let newPosX = posX - transition;

      // Reset the position when the text goes off the left edge
      if (newPosX < (-crawler.offsetWidth / 2) - resetValue) {
        setWait(true);
        setTimeout(() => setWait(false), 1000);
        newPosX = 0;
      }

      setPosX(newPosX);
    } else {
      setPosX(0);
    }
  };

  useEffect(() => {
    const crawler = document.getElementById(elementId);
    const container = document.getElementById(containerId);
    let animation = 0;

    if (crawler && container && crawler.offsetWidth > container.offsetWidth) {
      setDuplicateText(true);
      animation = requestAnimationFrame(moveCrawler);
    }

    return () => cancelAnimationFrame(animation);
  }, [posX, elementId, wait]);

  return (
    <CrawlerContainer id={containerId}>
      <CrawlerTextWrapper
        style={{
          transform: `translateX(${posX}px)`,
        }}
      >
        {children({ duplicateText })}
      </CrawlerTextWrapper>
    </CrawlerContainer>
  );
};

export default Crawler;
