import { alpha, Button } from '@mui/material';
import MuiDivider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';

export const Divider = styled(MuiDivider)(({ theme }) => ({
  borderColor: alpha(theme.palette.common.white, 0.3),
  margin: theme.spacing(2.5, 0),
}));

export const RoundedButton = styled(Button)(({ theme }) => ({
  borderRadius: '22px',
  padding: theme.spacing(1, 3, 1, 2),
  '& .MuiButton-startIcon': {
    marginRight: theme.spacing(0.5),
  },
  marginBottom: theme.spacing(4.5),
}));
