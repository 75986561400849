import { runGetApiRequest } from 'services/Api';

import { ACCOUNT_AVAILABLE_AMOUNT, ACCOUNT_CURRENCIES_API, CURRENCY_DETAILS_ROUTE } from 'constants/apiRoutes';
import { formatRoute } from 'utils/formatters';

export const getCurrencyDetails = (code: string) => (
  runGetApiRequest(formatRoute(CURRENCY_DETAILS_ROUTE, { code }))
);

export const getAccountBalance = () => (
  runGetApiRequest(ACCOUNT_AVAILABLE_AMOUNT)
);

export const getAccountCurrencies = (codes?: string) => (
  runGetApiRequest(ACCOUNT_CURRENCIES_API, codes ? { codes } : {})
);
