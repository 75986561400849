import {
  User as FbUser,
  OAuthProvider,
  AuthErrorCodes,
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  sendEmailVerification,
  signInWithEmailAndPassword,
  updateProfile,
  signInWithPopup,
  getAdditionalUserInfo,
} from 'firebase/auth';
import { get, query } from 'firebase/database';
import { useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { AUTO_CLOSE_TOAST } from 'components/NotificationContainer';
import useLikeAndFollow from 'containers/ClosingSlide/useLikeAndFollow';
import { FbUserContext, FbUserDispatchContext } from 'context/FbUserContext';
import { LeaderboardDispatchContext } from 'context/LeaderboardContext';

import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { clientInfoRef } from 'services/FirebaseRefs';
import { updateUserInfo } from 'services/User';
import { refreshCampaignDetails } from 'store/campaign/campaignSlice';
import { campaignSelector } from 'store/campaign/selectors';
import { refreshLeaderboard } from 'store/leaderboard/leaderboardSlice';
import { claimTokensSelector } from 'store/signinInvitation/selectors';
import {
  setAuthType,
  setClaimTokens,
  setData,
  setIsSubmitting,
  setTempUser
} from 'store/signinInvitation/signinInvitationSlice';
import { showFollowCreatorSelector, showProgressSelector } from 'store/system/selectors';
import { fireConfetti, setShakeSlide, setShowFollowCreator, setShowProgress } from 'store/system/systemSlice';

import { auth, getIntlMessageFromFirebaseError } from 'configuration/firebase';
import { AuthProviderId, MergeStatus } from 'constants/enums';
import { ClientPin, getClientId, getUserAgent } from 'utils/api';
import { isEmailValid } from 'utils/error';
import { extractNameFromEmail } from 'utils/user';

export enum AuthType {
  Login = 'Login',
  Registration = 'Registration',
  Verify = 'Verify',
  ForgotPassword = 'ForgotPassword'
}

export const useAuth = (onSuccess?: (shouldRefreshContent?: boolean) => void) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { campaignId } = useParams();
  const { bounty } = useAppSelector(campaignSelector) || {};
  const claimTokens = useAppSelector(claimTokensSelector);
  const showFollowCreator = useAppSelector(showFollowCreatorSelector) || false;
  const showProgress = useAppSelector(showProgressSelector) || false;
  const { fbUser: loggedFbUser } = useContext(FbUserContext);
  const { stopCheck } = useContext(LeaderboardDispatchContext);
  const { setMergedUser } = useContext(FbUserDispatchContext);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const { onFollowUser } = useLikeAndFollow(bounty?.creator?.id, bounty);

  const updateUser = async (fbUser: FbUser, fallbackName?: string) => {
    const anonUserId = loggedFbUser?.isAnonymous ? loggedFbUser.uid : null;

    try {
      const payload: any = {
        meta: {
          action: 'update_user',
          clientId: getClientId(),
          userId: fbUser.uid,
          agent: getUserAgent(),
          restEvent: true,
        },
        userId: fbUser.uid,
        name: fbUser.displayName || fallbackName,
        email: fbUser.email,
        clientId: getClientId(),
      };

      if (anonUserId) {
        payload.externalUserId = anonUserId;
        payload.externalUserSource = 'APP_ANON';
      }

      const result = await updateUserInfo(fbUser.uid, payload);

      if (claimTokens && result?.data?.mergeStatus === MergeStatus.AlreadyMerged) {
        setTimeout(
          () => toast.warning(
            intl.formatMessage({ id: 'label.youAlreadyEarnTokenWithThisAccount' }),
            { autoClose: false }
          ),
          AUTO_CLOSE_TOAST + 1000,
        );
      }
    } catch {
      await auth.signOut();
    }
  };

  const checkEmail = async (email: string) => {
    const result = await fetchSignInMethodsForEmail(auth, email);

    if (!result.length) {
      return dispatch(setAuthType(AuthType.Registration));
    }

    return dispatch(setAuthType(AuthType.Login));
  };

  const shakeSlide = () => {
    dispatch(setShakeSlide(true));
    setTimeout(() => dispatch(setShakeSlide(false)), 10000);
  };

  const refreshData = async (fbUser: FbUser) => {
    if (showProgress) {
      dispatch(setShowProgress(false));
      dispatch(fireConfetti(true));
      shakeSlide();
    }

    if (claimTokens) {
      stopCheck();
      dispatch(setClaimTokens(false));

      if (campaignId) {
        await dispatch(refreshLeaderboard({ bountyId: campaignId, excludeAnon: true }));
      }
    }

    if (showFollowCreator) {
      await onFollowUser(true, fbUser.uid);
      dispatch(setShowFollowCreator(false));
    }

    if (campaignId) {
      await dispatch(refreshCampaignDetails(campaignId));
    }
  };

  const signin = async (email: string, password: string) => {
    try {
      dispatch(setIsSubmitting(true));
      const { user } = await signInWithEmailAndPassword(auth, email, password);

      //  TODO: get userInfo before doing the update user
      await updateUser(user);
      const dataSnapshot = await get(query(clientInfoRef(ClientPin)));
      const clientInfo = dataSnapshot.val();

      if (clientInfo?.validateEmail && !user.emailVerified) {
        dispatch(setAuthType(AuthType.Verify));
        dispatch(setTempUser(user));
        dispatch(setData({ email, password: '' }));
        await auth.signOut();
        await refreshData(user);
      } else {
        setMergedUser(user);
        toast.success(intl.formatMessage(
          { id: user.displayName ? 'label.successfullyLoggedInAs' : 'label.successfullyLoggedIn' },
          { name: user.displayName },
        ));

        await refreshData(user);
        if (onSuccess) {
          onSuccess(true);
        }
      }
    } catch (e: any) {
      const errorMessage = getIntlMessageFromFirebaseError(e?.code);

      if (errorMessage) {
        setErrors({ email: intl.formatMessage({ id: errorMessage }) });
      } else {
        toast.warning(intl.formatMessage({ id: 'error.firebase.signIn' }));
      }
    } finally {
      dispatch(setIsSubmitting(false));
    }
  };

  const signup = async (email: string, password: string, name: string) => {
    try {
      dispatch(setIsSubmitting(true));
      const { user } = await createUserWithEmailAndPassword(auth, email, password);
      await updateProfile(user, { displayName: name });

      //  TODO: get userInfo before doing the update user
      await updateUser(user);
      const dataSnapshot = await get(query(clientInfoRef(ClientPin)));
      const clientInfo = dataSnapshot.val();

      if (clientInfo?.validateEmail) {
        dispatch(setTempUser(user));
        dispatch(setData({ email, password: '', name: '' }));
        dispatch(setAuthType(AuthType.Verify));

        sendEmailVerification(user);
        await auth.signOut();
        await refreshData(user);
      } else {
        setMergedUser(user);
        toast.success(intl.formatMessage(
          { id: user.displayName ? 'label.successfullyLoggedInAs' : 'label.successfullyLoggedIn' },
          { name: user.displayName },
        ));
        await refreshData(user);

        if (onSuccess) {
          onSuccess(true);
        }
      }
    } catch (e: any) {
      const errorMessage = getIntlMessageFromFirebaseError(e?.code);

      if (errorMessage) {
        setErrors({ email: intl.formatMessage({ id: errorMessage }) });
      } else {
        toast.warning(intl.formatMessage({ id: 'error.firebase.createUser' }));
      }
    } finally {
      dispatch(setIsSubmitting(false));
    }
  };

  const signInUserWithProvider = async (providerName: AuthProviderId) => {
    try {
      dispatch(setIsSubmitting(true));
      const provider = new OAuthProvider(providerName);
      provider.setCustomParameters({
        prompt: 'select_account'
      });

      const authResult = await signInWithPopup(auth, provider);
      const { user } = authResult;
      const additionalUserInfo = getAdditionalUserInfo(authResult);
      let name;

      if (additionalUserInfo?.isNewUser) {
        name = user?.displayName || (user.email ? extractNameFromEmail(user.email) : undefined);
      }

      await updateUser(user, name);
      await updateProfile(user, { displayName: name });
      setMergedUser(user);
      toast.success(intl.formatMessage(
        { id: user.displayName ? 'label.successfullyLoggedInAs' : 'label.successfullyLoggedIn' },
        { name: user.displayName },
      ));
      await refreshData(user);

      if (onSuccess) {
        onSuccess(true);
      }
    } catch (error: any) {
      if (![AuthErrorCodes.EXPIRED_POPUP_REQUEST, AuthErrorCodes.POPUP_CLOSED_BY_USER].includes(error?.code)) {
        toast.error(error.message);
      }
    } finally {
      dispatch(setIsSubmitting(false));
    }
  };

  return {
    checkEmail,
    signin,
    signup,
    signInUserWithProvider,
    errors,
    setErrors,
  };
};

export const useValidation = () => {
  const isValid = (data: Record<string, string>, authType: string) => {
    if (!data.email || !isEmailValid(data.email)) {
      return false;
    }

    if (!authType) {
      return true;
    }

    if (!data.password || data.password.length < 6) {
      return false;
    }

    return !(authType === AuthType.Registration && !data.name);
  };

  return {
    isValid,
  };
};
