import { alpha, Box } from '@mui/material';
import { keyframes, styled } from '@mui/material/styles';

export const AvatarWrapper = styled(
  Box,
  { shouldForwardProp: (props) => props !== 'width' },
)<{ width: number }>(({ theme, width }) => ({
  borderRadius: '50%',
  backgroundColor: alpha(theme.palette.general.lightGrey6, 0.6),
  width: `${width}px`,
  height: `${width}px !important`,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  zIndex: 1300,
}));

const sweepAnimation = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

export const RadarWrapper = styled(Box)(() => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  borderRadius: '50%',
  overflow: 'hidden',
}));

export const RadarAnimation = styled(Box)(() => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  borderRadius: '50%',
  transformOrigin: 'center',
  background: `
    linear-gradient(
      135deg,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(255, 255, 255, 0.3) 10%,
      rgba(255, 255, 255, 0) 70%
    )
  `,
  animation: `${sweepAnimation} 3s infinite linear`,
  opacity: 0.5,

  '&:after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    borderRadius: '50%',
    background: `
    linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.3) 0%,
      rgba(255, 255, 255, 0) 100%
    )`
  }
}));
