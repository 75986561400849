import { Fade } from '@mui/material';
import { FC, ReactNode } from 'react';

import LoadingStory from 'components/LoadingStory';
import { LoadingWrapper, Root } from 'layouts/SeriesLayout/index.styled';

import useStoryDimensions from 'hooks/useStoryDimensions';

import { StoryOverlay } from 'assets/theme/styled/StoryOverlay.styled';

interface SeriesLayoutProps {
  children: ReactNode | ReactNode[];
  isVisible: boolean;
  isOverlay: boolean;
  isLoading: boolean;
}

const SeriesLayout: FC<SeriesLayoutProps> = ({
  children,
  isVisible,
  isOverlay,
  isLoading,
}) => {
  const { height } = useStoryDimensions();

  if (isOverlay) {
    return (
      <Fade timeout={500} in={isVisible} unmountOnExit mountOnEnter>
        {isLoading
          ? (
            <LoadingWrapper height={height}>
              <LoadingStory />
            </LoadingWrapper>
          ) : (
            <StoryOverlay height={height} className="hidden-scroll" opacity={1} sx={{ p: 4 }}>
              {children}
            </StoryOverlay>
          )}
      </Fade>
    );
  }

  return (
    <Root className="hidden-scroll">
      {children}
    </Root>
  );
};

export default SeriesLayout;
