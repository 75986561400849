/* eslint max-len: 0 */
import { ReactElement } from 'react';

import { CustomIcon } from 'models/icon.interface';

const BarChart = ({ color = 'current', size = 24 }: CustomIcon): ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height={size} viewBox="0 0 24 24" width={size} fill={color}>
    <g>
      <rect fill="none" height="24" width="24"/>
    </g>
    <g>
      <g>
        <rect height="11" width="4" x="4" y="9"/>
        <rect height="7" width="4" x="16" y="13"/>
        <rect height="16" width="4" x="10" y="4"/>
      </g>
    </g>
  </svg>
);

export default BarChart;
