import { onValue } from 'firebase/database';
import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';

import { FbUserContext } from 'context/FbUserContext';

import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { userSocialActionStateRef } from 'services/FirebaseRefs';
import { followUser, updateSocialActionState } from 'services/User';
import { toggleCampaignLikedState } from 'store/campaign/campaignSlice';
import { campaignDetailsSelector } from 'store/campaign/selectors';
import { setShowFollowCreator } from 'store/system/systemSlice';

import { UserSocial } from 'constants/enums';
import { Bounty } from 'models/bounty.interface';

const useLikeAndFollow = (followUserId?: string, bounty?: Bounty) => {
  const { fbUser } = useContext(FbUserContext);
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const [isUnfollowOpen, setOpenUnfollowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFollowing, setIsFollowing] = useState(false);
  const campaignDetails = useAppSelector(campaignDetailsSelector) || bounty;

  useEffect(() => {
    if (followUserId && fbUser && !fbUser?.isAnonymous) {
      onValue(
        userSocialActionStateRef(fbUser.uid, followUserId, UserSocial.Follow),
        async (dataSnapshot) => {
          const result = dataSnapshot.val();
          const following = !!(result && result.actedAt);
          setIsFollowing(following);
        },
      );
    }
  }, [followUserId]);

  const handleLike = () => {
    if (fbUser && campaignDetails) {
      dispatch(toggleCampaignLikedState({ bounty: campaignDetails }));
    }
  };

  const onFollow = () => {
    if (fbUser?.isAnonymous) {
      return dispatch(setShowFollowCreator(true));
    }

    if (isFollowing) {
      return setOpenUnfollowModal(true);
    }

    if (!fbUser) {
      return toast.error(intl.formatMessage({ id: 'error.cannotSaveResponse' }));
    }

    return handleFollowUser(true, fbUser.uid);
  };

  const onUnfollowUser = async () => {
    if (!fbUser) {
      return toast.error(intl.formatMessage({ id: 'error.cannotSaveResponse' }));
    }
    return handleFollowUser(false, fbUser.uid);
  };

  const handleFollowUser = async (follow: boolean, userId: string) => {
    if (!fbUser || !followUserId) {
      toast.error(intl.formatMessage({ id: 'error.cannotSaveResponse' }));
      return;
    }

    try {
      setIsLoading(true);

      await followUser(followUserId, follow);
      await updateSocialActionState(
        userId,
        followUserId,
        UserSocial.Follow,
        follow ? { actedAt: new Date().getTime(), pending: false } : null,
      );
      setIsFollowing(follow);

      if (isUnfollowOpen) {
        setOpenUnfollowModal(false);
      }
    } catch {
      toast.error(intl.formatMessage({ id: 'error.cannotSaveResponse' }));
    } finally {
      setIsLoading(false);
    }
  };

  const closeUnfollowModal = () => setOpenUnfollowModal(false);

  return {
    onLike: handleLike,
    onFollow,
    onFollowUser: handleFollowUser,
    onUnfollowUser,
    closeUnfollowModal,
    isLoading,
    isFollowing,
    isUnfollowOpen,
  };
};

export default useLikeAndFollow;
