import { styled } from '@mui/material/styles';

export const Banner = styled('div')<{ src?: string }>(({ theme, src }) => ({
  borderTopLeftRadius: '8px',
  borderTopRightRadius: '8px',
  height: '175px',
  position: 'relative',
  marginBottom: '60px',
  backgroundColor: theme.palette.general.reddishBrown,
  backgroundImage: src ? `url(${src})` : 'none',
  backgroundSize: 'cover',
}));

export const AvatarWrapper = styled('div')(({ theme }) => ({
  position: 'absolute',
  bottom: '-60px',
  left: theme.spacing(2),
  border: `8px solid ${theme.palette.general.darkGrey4}`,
  borderRadius: '50%',
  opacity: 1,
}));
