import { Box, Typography, Button } from '@mui/material';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { Id } from 'react-toastify';

import StoryDialog from 'components/StoryDialog';

interface UnfollowDialogProps {
  username: string;
  closeUnfollowModal: () => void;
  onUnfollowUser: () => Promise<void | Id>;
  isLoading: boolean;
}

const UnfollowDialog:FC<UnfollowDialogProps> = ({ username, isLoading, onUnfollowUser, closeUnfollowModal }) => {
  const intl = useIntl();
  return (
    <StoryDialog isOpen onClose={closeUnfollowModal}>
      <Typography variant="h2">
        {intl.formatMessage(
          { id: 'label.unfollowUser' },
          { name: username },
        )}
      </Typography>
      <Box display="flex" gap={1} width="100%" justifyContent="center" mt={4}>
        <Button
          onClick={closeUnfollowModal}
          variant="text"
          disabled={isLoading}
        >
          {intl.formatMessage({ id: 'button.cancel' })}
        </Button>
        <Button
          onClick={onUnfollowUser}
          disabled={isLoading}
          variant="contained"
        >
          {intl.formatMessage({ id: 'button.confirm' })}
        </Button>
      </Box>
    </StoryDialog>
  );
};

export default UnfollowDialog;
