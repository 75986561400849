import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ConfirmationType } from 'constants/enums';
import { UserData } from 'models/userData.interface';

export interface SystemState {
  isConfettiFired: boolean;
  showFollowCreator: boolean;
  showChallengeGuide: boolean;
  showNotifyMe: boolean;
  showProgress: boolean;
  shakeSlide: boolean;
  isSeeking: boolean;
  hintsConfirmation: {
    [ConfirmationType.HideHowItWorksCampaignHint]: boolean;
    [ConfirmationType.HideHowItWorksPollHint]: boolean;
    [ConfirmationType.HideHowItWorksQuizHint]: boolean;
    [ConfirmationType.HideHowItWorksTriviaHint]: boolean;
    [ConfirmationType.HideHowItWorksChallengeHint]: boolean;
    [ConfirmationType.HideHowItWorksLotteryHint]: boolean;
    [ConfirmationType.HideHowItWorksFundingHint]: boolean;
    [ConfirmationType.HideChallengeRepliesHint]: boolean;
  }
}

export const initialState: SystemState = {
  isConfettiFired: false,
  showFollowCreator: false,
  showChallengeGuide: false,
  showProgress: false,
  showNotifyMe: false,
  shakeSlide: false,
  isSeeking: false,
  hintsConfirmation: {
    [ConfirmationType.HideHowItWorksCampaignHint]: false,
    [ConfirmationType.HideHowItWorksPollHint]: false,
    [ConfirmationType.HideHowItWorksQuizHint]: false,
    [ConfirmationType.HideHowItWorksTriviaHint]: false,
    [ConfirmationType.HideHowItWorksChallengeHint]: false,
    [ConfirmationType.HideHowItWorksLotteryHint]: false,
    [ConfirmationType.HideHowItWorksFundingHint]: false,
    [ConfirmationType.HideChallengeRepliesHint]: false,
  }
};

export const systemSlice = createSlice({
  name: 'system',
  initialState,
  reducers: {
    hintsConfirmation: (state) => {
      state.hintsConfirmation = initialState.hintsConfirmation;
      return state;
    },
    fireConfetti: (state, action: PayloadAction<boolean>) => {
      state.isConfettiFired = action.payload;
      return state;
    },
    setShowFollowCreator: (state, action: PayloadAction<boolean>) => {
      state.showFollowCreator = action.payload;
      return state;
    },
    setShowChallengeGuide: (state, action: PayloadAction<boolean>) => {
      state.showChallengeGuide = action.payload;
      return state;
    },
    setShowNotifyMe: (state, action: PayloadAction<boolean>) => {
      state.showNotifyMe = action.payload;
      return state;
    },
    setIsSeeking: (state, action: PayloadAction<boolean>) => {
      state.isSeeking = action.payload;
      return state;
    },
    setShowProgress: (state, action: PayloadAction<boolean>) => {
      state.showProgress = action.payload;
      return state;
    },
    setShakeSlide: (state, action: PayloadAction<boolean>) => {
      state.shakeSlide = action.payload;
      return state;
    },
    setHintConfirmation: (state, action: PayloadAction<{ type: ConfirmationType, value: boolean }>) => {
      const { type, value } = action.payload;
      state.hintsConfirmation = {
        ...state.hintsConfirmation,
        [type]: value,
      };
    },
    prepareHints: (state, action: PayloadAction<UserData>) => {
      const userData = action.payload;

      state.hintsConfirmation = {
        [ConfirmationType.HideHowItWorksCampaignHint]: Boolean(
          userData?.settings?.confirmations?.[ConfirmationType.HideHowItWorksCampaignHint]?.confirmed
        ),
        [ConfirmationType.HideHowItWorksPollHint]: Boolean(
          userData?.settings?.confirmations?.[ConfirmationType.HideHowItWorksPollHint]?.confirmed
        ),
        [ConfirmationType.HideHowItWorksQuizHint]: Boolean(
          userData?.settings?.confirmations?.[ConfirmationType.HideHowItWorksQuizHint]?.confirmed
        ),
        [ConfirmationType.HideHowItWorksTriviaHint]: Boolean(
          userData?.settings?.confirmations?.[ConfirmationType.HideHowItWorksTriviaHint]?.confirmed
        ),
        [ConfirmationType.HideHowItWorksChallengeHint]: Boolean(
          userData?.settings?.confirmations?.[ConfirmationType.HideHowItWorksChallengeHint]?.confirmed
        ),
        [ConfirmationType.HideHowItWorksLotteryHint]: Boolean(
          userData?.settings?.confirmations?.[ConfirmationType.HideHowItWorksLotteryHint]?.confirmed
        ),
        [ConfirmationType.HideHowItWorksFundingHint]: Boolean(
          userData?.settings?.confirmations?.[ConfirmationType.HideHowItWorksFundingHint]?.confirmed
        ),
        [ConfirmationType.HideChallengeRepliesHint]: Boolean(
          userData?.settings?.confirmations?.[ConfirmationType.HideChallengeRepliesHint]?.confirmed
        ),
      };

      return state;
    }
  },
});

export const {
  hintsConfirmation,
  fireConfetti,
  setIsSeeking,
  setShowFollowCreator,
  setShowChallengeGuide,
  setShowNotifyMe,
  setShowProgress,
  prepareHints,
  setHintConfirmation,
  setShakeSlide,
} = systemSlice.actions;

export default systemSlice.reducer;
