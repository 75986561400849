import { Typography } from '@mui/material';
import { FC } from 'react';

import { Root } from 'components/Discount/index.styled';

interface DiscountProps {
  discount: string;
}

const Discount:FC<DiscountProps> = ({ discount }) => (
  <Root>
    <Typography variant="subtitle" fontWeight={700}>
      {discount}
    </Typography>
  </Root>
);

export default Discount;
