import { FC } from 'react';
import { useIntl } from 'react-intl';

import UnfollowDialog from 'components/UnfollowDialog';
import useLikeAndFollow from 'containers/ClosingSlide/useLikeAndFollow';
import { FollowButton } from 'containers/MerchantProfile/Follow/index.styled';

interface FollowProps {
  username: string;
  userId: string;
}

const Follow:FC<FollowProps> = ({ username, userId }) => {
  const intl = useIntl();
  const {
    onFollow,
    onUnfollowUser,
    closeUnfollowModal,
    isLoading,
    isFollowing,
    isUnfollowOpen,
  } = useLikeAndFollow(userId);

  return (
    <>
      <FollowButton isFollowing={isFollowing} onClick={onFollow}>
        {intl.formatMessage({ id: isFollowing ? 'button.following' : 'button.follow' })}
      </FollowButton>

      {isUnfollowOpen && (
        <UnfollowDialog
          username={username}
          closeUnfollowModal={closeUnfollowModal}
          onUnfollowUser={onUnfollowUser}
          isLoading={isLoading}
        />
      )}
    </>
  );
};

export default Follow;
