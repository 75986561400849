import { alpha } from '@mui/material';
import { FC, useEffect } from 'react';
import { Action } from 'react-insta-stories/dist/interfaces';
import { useIntl } from 'react-intl';

import MessageDialog from 'components/MessageDialog';
import StoryDialog from 'components/StoryDialog';
import Authentication from 'containers/Authentication';

import { useAppSelector, useAppDispatch } from 'hooks/useRedux';
import {
  contentSelector,
  isInvitationOpenSelector,
  isSignInOpenSelector,
  loginMethodSelector,
} from 'store/signinInvitation/selectors';
import { closeModalInvitation, closeModalSignIn, openModalSignIn } from 'store/signinInvitation/signinInvitationSlice';

interface SigninInvitationProps {
  onAction?: Action;
}

const SigninInvitation:FC<SigninInvitationProps> = ({ onAction }) => {
  const isInvitationOpen = useAppSelector(isInvitationOpenSelector);
  const isSignInOpen = useAppSelector(isSignInOpenSelector);
  const loginMethod = useAppSelector(loginMethodSelector);
  const { content, values } = useAppSelector(contentSelector);

  const dispatch = useAppDispatch();
  const intl = useIntl();

  useEffect(() => {
    if ((isSignInOpen || isInvitationOpen) && onAction) {
      onAction('pause');
    }
  }, [isInvitationOpen, isSignInOpen]);

  const handleClose = () => {
    dispatch(closeModalInvitation());

    if (onAction) {
      onAction('play');
    }
  };

  const handleCloseSignUp = () => {
    dispatch(closeModalSignIn());

    if (onAction) {
      onAction('play');
    }
  };

  const handleOpenSignUp = () => {
    dispatch(openModalSignIn());
  };

  return (
    <>
      {isInvitationOpen && content && (
        <MessageDialog
          isOpen={isInvitationOpen}
          onClose={handleClose}
          title={intl.formatMessage({ id: content.title }, values)}
          linkMessage={intl.formatMessage({ id: content.message }, values)}
          onClick={handleOpenSignUp}
        />
      )}

      <StoryDialog
        sx={{ backgroundColor: loginMethod ? '#fff' : alpha('#fff', 0.8) }}
        isOpen={isSignInOpen}
        onClose={() => handleCloseSignUp()}
      >
        <Authentication
          onSuccess={handleCloseSignUp}
        />
      </StoryDialog>
    </>
  );
};

export default SigninInvitation;
