import { Typography, Grid2 as Grid } from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import CampaignCard from 'containers/MerchantProfile/CampaignCard';
import CampaignCardSkeleton from 'containers/MerchantProfile/CampaignCard/index.skeleton';

import { getUserCampaigns } from 'services/Campaign';

import { paginationItemsPerPage } from 'constants/general';
import { Bounty } from 'models/bounty.interface';
import { handleApiErrors } from 'utils/error';

interface CampaignsProps {
  authorName: string;
}

const Campaigns:FC<CampaignsProps> = ({ authorName }) => {
  const intl = useIntl();
  const { userId } = useParams();
  const [campaigns, setCampaigns] = useState<Bounty[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState(0);

  const hasMore = useMemo(() => totalCount > campaigns?.length, [totalCount, campaigns]);

  useEffect(() => {
    if (userId) {
      fetchCampaigns(userId);
    }
  }, [userId]);

  const fetchCampaigns = async (id: string, page = 0) => {
    try {
      setIsLoading(true);
      const { data } = await getUserCampaigns(id, page);

      setCampaigns((prevCampaigns) => ([
        ...(page ? prevCampaigns : []),
        ...data?.list ?? []
      ]));
      setTotalCount(data?.totalCount);
      setCurrentPage(page);
    } catch (e) {
      handleApiErrors(e);
    } finally {
      setIsLoading(false);
    }
  };

  const loadCampaigns = async () => {
    const maxPage = totalCount ? Math.floor(totalCount / paginationItemsPerPage) : 0;
    const newPage = currentPage + 1;

    if (isLoading || !userId) {
      return;
    }

    if (newPage > maxPage) {
      setIsLoading(false);
    } else {
      fetchCampaigns(userId, newPage);
    }
  };

  return (
    <div>
      {campaigns?.length > 0 || isLoading ? (
        <InfiniteScroll
          pageStart={0}
          loadMore={loadCampaigns}
          hasMore={hasMore}
          useWindow={false}
          threshold={200}
          getScrollParent={() => document.getElementById('profileContent')}
        >
          <Grid container spacing={2}>
            {campaigns?.map((bounty) => (
              <Grid size={6} key={bounty.id}>
                <CampaignCard bounty={bounty} />
              </Grid>
            ))}
            {isLoading && (
              <>
                <Grid size={6}>
                  <CampaignCardSkeleton/>
                </Grid>
                <Grid size={6}>
                  <CampaignCardSkeleton/>
                </Grid>
                <Grid size={6}>
                  <CampaignCardSkeleton/>
                </Grid>
                <Grid size={6}>
                  <CampaignCardSkeleton/>
                </Grid>
              </>
            )}
          </Grid>
        </InfiniteScroll>
      ) : (
        <Typography variant="h2" textAlign="center">
          {intl.formatMessage({ id: 'label.noCampaigns' }, { username: authorName })}
        </Typography>
      )}
    </div>
  );
};

export default Campaigns;
