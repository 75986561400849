import { useEffect, useRef, useState } from 'react';

export const useAnimatedValue = (
  prev: number,
  current: number,
  duration: number,
): { animatedValue: number } => {
  const [animatedValue, setAnimatedValue] = useState(prev);
  const prevRef = useRef(prev);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const diff = current - prevRef.current;
    const stepTime = duration / Math.abs(diff);

    // Clear any existing interval
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    const countEffect = (): void => {
      setAnimatedValue((prevValue) => {
        const nextValue =
          diff > 0
            ? Math.min(prevValue + 1, current)
            : Math.max(prevValue - 1, current);

        if (nextValue === current) {
          if (intervalRef.current) {
            clearInterval(intervalRef.current);
          }
        }

        return nextValue;
      });
    };

    intervalRef.current = setInterval(countEffect, stepTime);

    // Cleanup on unmount or when dependencies change
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [current, duration]);

  useEffect(() => {
    prevRef.current = animatedValue;
  }, [animatedValue]);

  return { animatedValue };
};
