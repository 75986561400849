import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledOldPrice = styled('span')(({ theme }) => ({
  textDecoration: 'line-through',
  marginRight: theme.spacing(1.5),
  color: theme.palette.text.secondary,
  fontWeight: 400,
}));

export const Price = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  whiteSpace: 'nowrap',
  position: 'absolute',
}));
