import { Box, CardMedia, Typography } from '@mui/material';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { InfoSection, Root } from 'containers/MerchantProfile/CampaignCard/index.styled';

import DefaultCover from 'assets/images/bkg-img.png';
import { CAMPAIGN_VIEW } from 'constants/clientRoutes';
import { Bounty } from 'models/bounty.interface';
import { formatRoute } from 'utils/formatters';

interface CampaignCardProps {
  bounty: Bounty;
}

const CampaignCard:FC<CampaignCardProps> = ({ bounty }) => {
  const navigate = useNavigate();

  const coverUrl = bounty?.attachments?.attachments
    ? Object.values(bounty?.attachments?.attachments)?.[0]?.url
    : DefaultCover;

  const openDetails = () => {
    navigate(formatRoute(CAMPAIGN_VIEW, { campaignId: bounty.id }), { replace: true });
  };

  return (
    <Root>
      <Box
        role="presentation"
        position="relative"
        className="pointer"
        onClick={openDetails}
      >
        <CardMedia
          component="img"
          alt={bounty.title}
          src={coverUrl}
        />

        <InfoSection>
          {bounty.title && (
            <Typography variant="h1" gutterBottom>
              {bounty.title}
            </Typography>
          )}
        </InfoSection>
      </Box>
    </Root>
  );
};

export default CampaignCard;
