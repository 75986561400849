import { styled } from '@mui/material/styles';

export const Root = styled('div')(({ theme }) => ({
  borderRadius: '10px',
  padding: theme.spacing(1),
  backgroundColor: theme.palette.common.white,
  color: theme.palette.common.black,
  marginBottom: theme.spacing(2),
  position: 'relative',
  height: '95%',

  '& .MuiCardMedia-root': {
    borderRadius: '6px',
    marginBottom: theme.spacing(1),
    height: '250px',

    [theme.breakpoints.down('sm')]: {
      height: '150px',
    },
  },

  '.skeletonImage': {
    height: '250px',
    marginBottom: theme.spacing(1),

    [theme.breakpoints.down('sm')]: {
      height: '150px',
    }
  },

  '& .oldPrice': {
    textDecoration: 'line-through',
    marginRight: theme.spacing(1.5),
    color: theme.palette.text.secondary,
    fontWeight: 400,
  }
}));
