import { RootState } from '~/store';

import { ConfirmationType } from 'constants/enums';

export const isFiredSelector = (state: RootState): boolean => state.system.isConfettiFired;
export const showFollowCreatorSelector = (state: RootState): boolean => state.system.showFollowCreator;
export const showChallengeGuideSelector = (state: RootState): boolean => state.system.showChallengeGuide;
export const showNotifyMeSelector = (state: RootState): boolean => state.system.showNotifyMe;
export const isChallengeGuideVisitedSelector = (state: RootState): boolean => (
  state.system.hintsConfirmation[ConfirmationType.HideChallengeRepliesHint]
);
export const hintsConfirmationSelector = (state: RootState): Record<string, boolean> => state.system.hintsConfirmation;
export const showProgressSelector = (state: RootState): boolean => state.system.showProgress;
export const shakeSlideSelector = (state: RootState): boolean => state.system.shakeSlide;
export const isSeekingSelector = (state: RootState): boolean => state.system.isSeeking;
