import { styled } from '@mui/material/styles';

export const AnimationWrapper = styled('div')(() => ({
  transform: 'rotate(45deg)',
  width: '150px',
  height: '150px',
  position: 'absolute',
  top: -100,
  zIndex: 1,
  left: -40,
  cursor: 'pointer',
}));

export const ActionsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  flexGrow: 2,
  justifyContent: 'flex-end',
  flexDirection: 'column',
  gap: theme.spacing(2),
  width: '100%',
}));
