import { CardMedia, Typography } from '@mui/material';
import { FC, useMemo } from 'react';

import Crawler from 'components/Crawler';
import Discount from 'components/Discount';
import ProductPrice from 'components/ProductPrice';
import { Root } from 'containers/MerchantProfile/ProductCard/index.styled';

import { Bounty } from 'models/bounty.interface';
import { getProductCover } from 'utils/attachments';
import { discountPercent, getPrices } from 'utils/price';

interface ProductCardProps {
  bounty: Bounty;
}

const ProductCard:FC<ProductCardProps> = ({ bounty }) => {
  const { product } = bounty;
  const coverUrl = useMemo(() => {
    if (!product) return '';

    const cover = getProductCover(
      product.imageUrl,
      300,
      300,
      product.imageCdn,
    );
    return cover.cdnUrl || cover.url;
  }, [bounty.id]);
  const { price, msrp } = product ? getPrices(product) : {};
  const productDiscount = discountPercent(msrp, price);

  return (
    <Root>
      <CardMedia
        component="img"
        alt={product?.name}
        src={coverUrl}
      />
      {productDiscount && <Discount discount={`- ${productDiscount}%`} />}

      <Crawler
        containerId={`title-container-crawler-${bounty.id}`}
        elementId={`title-crawler-${bounty.id}`}
        resetValue={12}
      >
        {({ duplicateText }) => (
          <Typography component="p" noWrap variant="subtitle1" color="textSecondary" id={`title-crawler-${bounty.id}`}>
            {product?.name}
            {duplicateText && (
              <>
                <span style={{ paddingRight: '24px' }} />
                {product?.name}
              </>
            )}
          </Typography>
        )}
      </Crawler>

      {price && (
        <ProductPrice
          price={price}
          msrp={msrp}
          isActive
          variant="subtitle1"
          productId={bounty.id}
        />
      )}
    </Root>
  );
};

export default ProductCard;
