import { Box, Button, Fade, Typography } from '@mui/material';
import Lottie from 'lottie-react';
import { FC, ReactNode } from 'react';
import { useIntl } from 'react-intl';

import { ActionsWrapper, AnimationWrapper } from 'layouts/FollowUserLayout/index.styled';

import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import useStoryDimensions from 'hooks/useStoryDimensions';
import { openModalSignIn } from 'store/signinInvitation/signinInvitationSlice';
import { showFollowCreatorSelector } from 'store/system/selectors';
import { setShowFollowCreator } from 'store/system/systemSlice';

import pointerAnimation from 'assets/animations/pointerAnimation.json';
import { StoryOverlay } from 'assets/theme/styled/StoryOverlay.styled';

interface FollowUserLayoutProps {
  children: ReactNode;
  title: ReactNode[];
  submitLabel: string
}

const FollowUserLayout:FC<FollowUserLayoutProps> = ({ children, title, submitLabel }) => {
  const dispatch = useAppDispatch();
  const showFollowCreator = useAppSelector(showFollowCreatorSelector) || false;
  const { height } = useStoryDimensions();
  const intl = useIntl();

  const handleSignIn = () => dispatch(openModalSignIn());
  const handleSkip = () => dispatch(setShowFollowCreator(false));

  return (
    <Fade timeout={500} in={showFollowCreator} unmountOnExit mountOnEnter>
      <StoryOverlay height={height} className="hidden-scroll">
        <Box display="flex" flexDirection="column" alignItems="center" height="100%">
          <Box flexGrow={1}>
            <Typography textAlign="center" color="common.white" variant="title1">
              {title}
            </Typography>
          </Box>

          {children}

          <ActionsWrapper>
            <Box position="relative">
              <Button
                onClick={handleSignIn}
                variant="contained"
                fullWidth
                className="big"
              >
                {submitLabel}
              </Button>
              <AnimationWrapper onClick={handleSignIn}>
                <Lottie animationData={pointerAnimation} loop />
              </AnimationWrapper>
            </Box>
            <Button
              onClick={handleSkip}
              variant="outlinedLightPrimary"
              fullWidth
              className="big"
            >
              {intl.formatMessage({ id: 'button.skipForNow' })}
            </Button>
          </ActionsWrapper>
        </Box>
      </StoryOverlay>
    </Fade>
  );
};

export default FollowUserLayout;
